import React from 'react';

import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';

const base64url = require('base64url');

require('es6-promise').polyfill();

const smartfeedNotFound = ({
  apiKey,
  groupOrIdParam,
  smartfeedCompanyData,
  appGatewayURL,
  vacanciesRouteLoading,
  vacanciesRouteError,
  siteConfig,
  location,
  id = 1,
}) => {
  const params = new URLSearchParams(location.search);
  const extraDataEncoded = params ? params.get('ExtraData') : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split('|')
    : [];

  return (
    <Layout
      siteConfig={siteConfig}
      location={location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      displayJACTA={false}
      description={
        extraDataDecodedSplit && extraDataDecodedSplit[4]
          ? extraDataDecodedSplit[4]
          : extraDataDecodedSplit && extraDataDecodedSplit[1]
          ? 'Check out this vacancy from ' + extraDataDecodedSplit[1]
          : 'Check out this vacancy.'
      }
      title={
        extraDataDecodedSplit &&
        extraDataDecodedSplit[0] &&
        extraDataDecodedSplit[1]
          ? extraDataDecodedSplit[0] + ' | ' + extraDataDecodedSplit[1]
          : extraDataDecodedSplit && extraDataDecodedSplit[0]
          ? extraDataDecodedSplit[0]
          : 'Loading page...'
      }
    >
      <div className="notfound">
        <section className="app-content">
          <div className="app-content-content">
            <div className="container">
              <div className="loading-panel">
                <div className="loading-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span className="loading-text">
                  Please wait... If content is not shown soon then you may have
                  the wrong URL.
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default smartfeedNotFound;
